<template>
    <h-col cols="12">

        <h2 v-if="!!titolo" :class="!!subTitolo ? 'pt-4 pb-4' : 'mt-4'">{{ titolo }}</h2>

        <h3 v-if="!!subTitolo" class="pt-4">{{ subTitolo }}</h3>

        <i>
            <slot>
                {{ testo }}
            </slot>
        </i>

    </h-col>
</template>

<script>
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'PrivacyCapitolo',
        components: {HCol},
        props: {titolo: String, subTitolo: String, testo: String}
    }
</script>

<style scoped>

</style>
