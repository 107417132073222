<template>
    <h-row justify="center">
        <h-col cols="11" md="6">
            <h-row>

                <h-col cols="12" class="pb-0">
                    <h1>H2H - Informativa sulla Privacy</h1>
                </h-col>

                <privacy-capitolo titolo="Titolare del Trattamento dei Dati"
                                  testo="Coming Soon"
                ></privacy-capitolo>

                <privacy-capitolo titolo="Tipologie di Dati raccolti">
                    Il Titolare <strong>NON</strong> raccoglie Dati Personali dell'utente.<br>
                    <br>
                    Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa
                    privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi.
                    I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti
                    automaticamente durante l'uso di questa Applicazione.<br>
                    Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se
                    l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questa Applicazione fornire il Servizio.
                    Nei casi in cui questa Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono liberi di
                    astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del
                    Servizio o sulla sua operatività.<br>
                    Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il
                    Titolare.<br>
                    L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o
                    dei titolari dei servizi terzi utilizzati da questa Applicazione, ove non diversamente precisato, ha la
                    finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel
                    presente documento e nella Cookie Policy, se disponibile.<br>
                    <br>
                    L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi
                    mediante questa Applicazione e garantisce di avere il diritto di comunicarli o diffonderli, liberando il
                    Titolare da qualsiasi responsabilità verso terzi.
                </privacy-capitolo>

                <privacy-capitolo titolo="Modalità e luogo del trattamento dei Dati raccolti"
                                  sub-titolo="Modalità di trattamento"
                >
                    Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la
                    modifica o la distruzione non autorizzate dei Dati Personali.<br>
                    Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative
                    e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi,
                    potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questa Applicazione
                    (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti
                    esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società
                    informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da
                    parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del
                    Trattamento.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Base giuridica del trattamento">
                    Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:<br>
                    <br>
                    <ul>
                        <li>
                            l’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni ordinamenti
                            il Titolare può essere autorizzato a trattare Dati Personali senza che debba sussistere il
                            consenso dell’Utente o un’altra delle basi giuridiche specificate di seguito, fino a quando
                            l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il
                            trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione
                            dei Dati Personali;
                        </li>
                        <li>
                            il trattamento è necessario all'esecuzione di un contratto con l’Utente e/o all'esecuzione di
                            misure precontrattuali;
                        </li>
                        <li>
                            il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;
                        </li>
                        <li>
                            il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per
                            l'esercizio di pubblici poteri di cui è investito il Titolare;
                        </li>
                        <li>
                            il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.
                        </li>
                    </ul>
                    <br>
                    È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun
                    trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un
                    contratto o necessario per concludere un contratto.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Luogo">
                    I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti
                    coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.<br>
                    I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente
                    si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento
                    alla sezione relativa ai dettagli sul trattamento dei Dati Personali.<br>
                    <br>
                    L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di
                    fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o
                    costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate
                    dal Titolare per proteggere i Dati.<br>
                    <br>
                    L’Utente può verificare se abbia luogo uno dei trasferimenti appena descritti esaminando la sezione di
                    questo documento relativa ai dettagli sul trattamento di Dati Personali o chiedere informazioni al
                    Titolare contattandolo agli estremi riportati in apertura.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Periodo di conservazione">
                    I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti.<br>
                    <br>
                    Pertanto:<br>
                    <ul>
                        <li>
                            I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e
                            l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.
                        </li>
                        <li>
                            I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno
                            trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori
                            informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di
                            questo documento o contattando il Titolare.
                        </li>
                    </ul>
                    <br>
                    Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più
                    a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a
                    conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine
                    di un’autorità.<br>
                    <br>
                    Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di
                    tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati
                    non potranno più essere esercitati.
                </privacy-capitolo>

                <privacy-capitolo titolo="Diritti dell’Utente">
                    Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.<br>
                    <br>
                    In particolare, l’Utente ha il diritto di:<br>
                    <br>
                    <ul>
                        <li>
                            <strong>revocare il consenso in ogni momento.</strong>
                            L’Utente può revocare il consenso al trattamento dei propri Dati Personali precedentemente
                            espresso.
                        </li>
                        <li>
                            <strong>opporsi al trattamento dei propri Dati.</strong>
                            L’Utente può opporsi al trattamento dei propri Dati quando esso avviene su una base giuridica
                            diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione
                            sottostante.
                        </li>
                        <li>
                            <strong>accedere ai propri Dati.</strong>
                            L’Utente ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati
                            aspetti del trattamento ed a ricevere una copia dei Dati trattati.
                        </li>
                        <li>
                            <strong>verificare e chiedere la rettificazione.</strong>
                            L’Utente può verificare la correttezza dei propri Dati e richiederne l’aggiornamento o la
                            correzione.
                        </li>
                        <li>
                            <strong>ottenere la limitazione del trattamento.</strong>
                            Quando ricorrono determinate condizioni, l’Utente può richiedere la limitazione del trattamento
                            dei propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro
                            conservazione.
                        </li>
                        <li>
                            <strong>ottenere la cancellazione o rimozione dei propri Dati Personali.</strong>
                            Quando ricorrono determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati
                            da parte del Titolare.
                        </li>
                        <li>
                            <strong>ricevere i propri Dati o farli trasferire ad altro titolare.</strong>
                            L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile
                            da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza
                            ostacoli ad un altro titolare. Questa disposizione è applicabile quando i Dati sono trattati con
                            strumenti automatizzati ed il trattamento è basato sul consenso dell’Utente, su un contratto di
                            cui l’Utente è parte o su misure contrattuali ad esso connesse.
                        </li>
                        <li>
                            <strong>proporre reclamo.</strong>
                            L’Utente può proporre un reclamo all’autorità di controllo della protezione dei dati personali
                            competente o agire in sede giudiziale.
                        </li>
                    </ul>
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Dettagli sul diritto di opposizione">
                    Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è
                    investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto
                    ad opporsi al trattamento per motivi connessi alla loro situazione particolare.<br>
                    <br>
                    Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto,
                    possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati
                    con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo
                    documento.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Come esercitare i diritti"
                                  testo="Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese."
                ></privacy-capitolo>

                <privacy-capitolo titolo="Ulteriori informazioni sul trattamento"
                                  sub-titolo="Difesa in giudizio"
                >
                    I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi
                    preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questa Applicazione
                    o dei Servizi connessi da parte dell’Utente.<br>
                    L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per
                    ordine delle autorità pubbliche.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Informative specifiche"
                                  testo="Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, questa Applicazione potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Log di sistema e manutenzione"
                                  testo="Per necessità legate al funzionamento ed alla manutenzione, questa Applicazione e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Risposta alle richieste “Do Not Track”">
                    Questa Applicazione non supporta le richieste “Do Not Track”.<br>
                    Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente è invitato a
                    consultare le rispettive privacy policy.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Modifiche a questa privacy policy">
                    Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in
                    qualunque momento notificandolo agli Utenti su questa pagina e, se possibile, su questa Applicazione
                    nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno
                    degli estremi di contatto di cui è in possesso. Si prega dunque di consultare con frequenza questa
                    pagina, facendo riferimento alla data di ultima modifica indicata in fondo.<br>
                    <br>
                    Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà
                    a raccogliere nuovamente il consenso dell’Utente, se necessario.
                </privacy-capitolo>

                <privacy-capitolo titolo="Informazioni per i consumatori californiani">
                    Questa parte del documento integra e completa le informazioni contenute nel resto della privacy policy ed
                    è fornita dalla società che gestisce questa Applicazione e, se del caso, dalla sua capogruppo e dalle sue
                    controllate e affiliate (ai fini di questa sezione denominate collettivamente "noi", "nostro" o
                    "nostra").<br>
                    <br>
                    Le disposizioni contenute in questa sezione si applicano a tutti gli Utenti che sono considerati
                    consumatori residenti nello stato della California, Stati Uniti d’America, ai sensi del “California
                    Consumer Privacy Act of 2018" (tali Utenti sono indicati di seguito semplicemente come “tu”, “tuo”, "voi"
                    o "vostro"), e, per costoro, queste disposizioni prevalgono su qualsiasi altra disposizione eventualmente
                    divergente o in contrasto contenuta nella presente privacy policy.<br>
                    <br>
                    In questa parte del documento viene utilizzato il termine “informazioni personali” così come definito dal
                    California Consumer Privacy Act (CCPA).
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Categorie di informazioni personali raccolte, divulgate o vendute">
                    In questa sezione sono riassunte le categorie di informazioni personali che abbiamo raccolto, comunicato
                    o “venduto” ai sensi del CCPA e le relative finalità del trattamento.<br>
                    <strong>
                        Puoi trovare informazioni dettagliate su tali attività nella sezione denominata “Dettagli sul
                        trattamento dei Dati Personali” presente in questo documento.
                    </strong>
                </privacy-capitolo>

                <privacy-capitolo
                    sub-titolo="Informazioni che raccogliamo: le categorie di informazioni personali che raccogliamo"
                >
                    Abbiamo raccolto le seguenti categorie di informazioni personali su di te: (nessun dato raccolto).<br>
                    <br>
                    Non raccoglieremo ulteriori categorie di informazioni personali senza prima comunicarti una nuova
                    informativa.
                </privacy-capitolo>

                <privacy-capitolo
                    sub-titolo="Come raccogliamo le informazioni: quali sono le fonti delle informazioni personali che raccogliamo?"
                >
                    Raccogliamo le suddette categorie di informazioni personali, direttamente o indirettamente, da te quando
                    utilizzi questa Applicazione.<br>
                    <br>
                    Per esempio, ci fornisci direttamente le tue informazioni personali quando invii richieste tramite
                    qualsiasi modulo presente su questa Applicazione. Inoltre, ci fornisci indirettamente informazioni
                    personali quando navighi su questa Applicazione, in quanto le informazioni personali che ti riguardano
                    vengono automaticamente osservate e raccolte. Infine, potremmo raccogliere le tue informazioni personali
                    da terze parti che lavorano con noi in relazione alla fornitura del Servizio o al funzionamento di questa
                    Applicazione e delle sue funzionalità.
                </privacy-capitolo>

                <privacy-capitolo
                    sub-titolo="Come utilizziamo le informazioni raccolte: condivisione e comunicazione delle tue informazioni personali con terze parti per finalità operative."
                >
                    Potremmo comunicare tue informazioni personali a terze parti per finalità operative e di servizio. In
                    questo caso, stipuliamo un contratto scritto con tale terza parte che obbliga il destinatario delle tue
                    informazioni personali a mantenere tali informazioni riservate e a non utilizzarle per scopi diversi da
                    quelli necessari per l'esecuzione del contratto.<br>
                    <br>
                    Potremmo inoltre comunicare le tue informazioni personali a terze parti quando sei tu a chiedercelo o ci
                    autorizzi a farlo al fine di fornirti il nostro Servizio.<br>
                    <br>
                    Per ulteriori informazioni sulle finalità del trattamento, ti invitiamo a consultare la relativa sezione
                    di questo documento.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Nessuna vendita delle tue informazioni personali"
                                  testo="Negli ultimi 12 mesi non abbiamo venduto alcuna delle tue informazioni personali."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Quali sono le finalità per le quali utilizziamo le tue informazioni personali?"
                >
                    Potremo utilizzare le tue informazioni personali per permettere il corretto funzionamento di questa
                    Applicazione e delle sue funzionalità (“finalità operative”). In tali casi, le tue informazioni personali
                    saranno trattate in maniera adeguata e proporzionata alle finalità per la quale erano state
                    originariamente raccolte e rigorosamente entro i limiti di finalità compatibili.<br>
                    <br>
                    Potremmo inoltre utilizzare le tue informazioni personali per altri motivi, come ad esempio per finalità
                    commerciali (così come indicato nella sezione “Dettagli sul trattamento dei Dati Personali” all’interno
                    del presente documento), oltre che per rispettare la legge e difendere i nostri diritti di fronte alle
                    autorità competenti quando i nostri diritti o interessi sono minacciati o quando subiamo un danno.<br>
                    <br>
                    Non utilizzeremo le tue informazioni personali per scopi diversi, non correlati o incompatibili, senza
                    prima comunicarti una nuova informativa.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="I tuoi diritti relativi alla privacy in California e come esercitarli"
                ></privacy-capitolo>

                <privacy-capitolo class="pt-0"
                                  sub-titolo="Diritto alla conoscenza e alla portabilità"
                >
                    Hai il diritto di conoscere quanto segue:<br>
                    <br>
                    <ul>
                        <li>
                            le categorie di informazioni personali che raccogliamo su di te e le fonti di tali informazioni,
                            le finalità per le quali utilizziamo le tue informazioni personali e con chi le condividiamo;
                        </li>
                        <li>
                            in caso di vendita o comunicazione di informazioni personali a terzi per fini operativi, ti
                            forniremo due liste separate nelle quali ti comunicheremo:<br>
                            <ul>
                                <li>
                                    per la vendita, le categorie di informazioni personali comunicate a ciascuna categoria di
                                    destinatari; e
                                </li>
                                <li>
                                    per la comunicazione a terzi per fini operativi, le categorie di informazioni personali
                                    ottenute da ciascuna categoria di destinatari;
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br>
                    Le informazioni di cui sopra saranno limitate alle informazioni personali raccolte o utilizzate negli
                    ultimi 12 mesi.<br>
                    <br>
                    Nel caso in cui la nostra risposta venga fornita elettronicamente, le informazioni in essa contenute
                    saranno “portabili”, cioè consegnate in un formato compatibile in modo da permetterti di trasmettere le
                    informazioni ad altre entità senza impedimenti – a condizione che ciò sia tecnicamente fattibile.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Diritto di richiedere la cancellazione delle proprie informazioni personali">
                    Hai il diritto di richiedere la cancellazione di qualsiasi tua informazione personale, fatte salve le
                    eccezioni previste dalla legge (come, a titolo esemplificativo e non esaustivo, nel caso in cui le
                    informazioni vengano utilizzate per identificare e riparare errori su questa Applicazione, per rilevare
                    incidenti di sicurezza, per finalità di protezione da attività fraudolente o illegali, per esercitare
                    determinati diritti etc.).<br>
                    <br>
                    Se non si applica nessuna eccezione prevista dalla legge, a seguito dell’esercizio del tuo diritto, noi
                    cancelleremo le tue informazioni personali e chiederemo ai nostri fornitori di fare altrettanto.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Come esercitare i tuoi diritti">
                    Per esercitare i diritti sopra descritti, è necessario presentare una richiesta verificabile
                    contattandoci tramite i recapiti forniti nel presente documento.<br>
                    <br>
                    Per poter rispondere alla tua richiesta, è necessario per noi essere in grado di identificarti. Per
                    questo puoi esercitare i diritti di cui sopra solo presentando una richiesta verificabile che deve:<br>
                    <br>
                    <ul>
                        <li>
                            fornire informazioni sufficienti che ci consentano di verificare in maniera ragionevole che tu
                            sia la persona cui le informazioni personali che abbiamo raccolto si riferiscono o un suo
                            rappresentante autorizzato;
                        </li>
                        <li>
                            descrivere la tua richiesta con un grado di dettaglio che sia sufficiente a farci comprendere,
                            valutare e rispondere correttamente a quanto ci chiedi.
                        </li>
                    </ul>
                    <br>
                    Non risponderemo a nessuna richiesta se non saremo in grado di verificare la tua identità e, quindi, di
                    confermare che le informazioni in nostro possesso si riferiscano effettivamente a te.<br>
                    <br>
                    Se non puoi presentare personalmente una richiesta verificabile, puoi delegare una persona registrata
                    presso il “California Secretary of State” a farlo per tuo conto.<br>
                    <br>
                    Se sei un adulto, puoi fare una richiesta verificabile per conto di chi ricade sotto la tua potestà
                    genitoriale.<br>
                    <br>
                    È possibile presentare un numero massimo di 2 richieste nell'arco di 12 mesi.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Come e in quanto tempo gestiremo la tua richiesta">
                    Entro 10 giorni ti confermeremo che abbiamo ricevuto la tua richiesta e ti forniremo informazioni su come
                    la elaboreremo.<br>
                    <br>
                    Risponderemo nel merito della richiesta entro 45 giorni dalla ricezione della stessa. Qualora avessimo
                    bisogno di più tempo, ti spiegheremo il motivo e ti comunicheremo di quanto tempo abbiamo bisogno. A
                    questo proposito, ti preghiamo di notare che potremmo impiegare fino a 90 giorni per soddisfare la tua
                    richiesta.<br>
                    <br>
                    Le nostre comunicazioni riguarderanno il periodo dei 12 mesi precedenti.<br>
                    <br>
                    Se dovessimo negare la tua richiesta, ti spiegheremo il motivo del rifiuto.<br>
                    <br>
                    Non applicheremo nessuna commissione per trattare o rispondere alla tua richiesta verificabile a meno che
                    essa non sia manifestatamente infondata o eccessiva. In tali casi, potremmo applicare una commissione
                    ragionevole, o negare la richiesta. In entrambi i casi, ti comunicheremo le nostre decisioni e ne
                    spiegheremo le ragioni.
                </privacy-capitolo>

                <privacy-capitolo titolo="Informazioni per gli Utenti residenti in Brasile">
                    Questa parte del documento integra e completa le informazioni contenute nel resto della privacy policy ed
                    è fornita dall’entità che gestisce questa Applicazione e, se del caso, dalla sua capogruppo e dalle sue
                    controllate e affiliate (ai fini di questa sezione denominate collettivamente "noi", "nostro" o
                    "nostra").<br>
                    Le disposizioni contenute in questa sezione si applicano a tutti gli Utenti che sono residenti in
                    Brasile, ai sensi della "Lei Geral de Proteção de Dados" (tali Utenti sono indicati di seguito
                    semplicemente come “tu”, “tuo”, "voi" o "vostro"). Per tali Utenti, queste disposizioni prevalgono su
                    qualsiasi altra disposizione eventualmente divergente o in contrasto contenuta nella presente privacy
                    policy.<br>
                    In questa parte del documento viene utilizzato il termine “informazioni personali” così come definito
                    dalla Lei Geral de Proteção de Dados (LGPD).
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Basi giuridiche in virtù delle quali trattiamo le tue informazioni personali">
                    Trattiamo le tue informazioni personali esclusivamente nel caso in cui sussita una delle basi giuridiche
                    per tale trattamento. Le basi giuridiche sono le seguenti:<br>
                    <br>

                    <ul>
                        <li>
                            il tuo consenso alle attività di trattamento in questione;
                        </li>
                        <li>
                            l’adempimento ad obblighi di legge che siamo tenuti a soddisfare;
                        </li>
                        <li>
                            l’esecuzione di norme dettate da leggi o regolamenti o da contratti, accordi o altri simili
                            strumenti giuridici;
                        </li>
                        <li>
                            gli studi condotti da enti di ricerca, preferibilmente effettuati su informazioni personali
                            anonimizzate;
                        </li>
                        <li>
                            l’esecuzione di un contratto e dei relativi adempimenti precontrattuali, qualora tu sia parte di
                            tale contratto;
                        </li>
                        <li>
                            l’esercizio dei nostri diritti in giudizio, in procedure amministrative o in arbitrati;
                        </li>
                        <li>
                            la difesa o l’incolumità fisica tua o di un terzo;
                        </li>
                        <li>
                            la tutela della salute - nel contesto di procedure messe in atto da entità o professionisti del
                            settore sanitario;
                        </li>
                        <li>
                            il nostro legittimo interesse, a condizione che i tuoi diritti e libertà fondamentali non
                            prevalgano su tali interessi; e
                        </li>
                        <li>
                            la tutela del credito.
                        </li>
                    </ul>
                    <br>
                    Per sapere di più riguardo alle basi giuridiche, puoi contattarci in qualsiasi momento utilizzando i
                    recapiti forniti in questo documento.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Categorie di informazioni personali trattate"
                                  testo="Per sapere quali sono categorie di informazioni personali trattate, puoi fare riferimento alla sezione “Dettagli sul trattamento dei Dati Personali” presente in questo documento."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Perchè trattiamo le tue informazioni personali"
                                  testo="Per sapere perchè trattiamo le tue informazioni personali, fai riferimento alle sezioni “Dettagli sul trattamento dei Dati Personali” e “Finalità del Trattamento dei Dati raccolti” presente in questo documento."
                ></privacy-capitolo>

                <privacy-capitolo
                    sub-titolo="I tuoi diritti relativi alla privacy in Brasile, come inoltrare una richiesta e come verrà gestita da noi"
                ></privacy-capitolo>

                <privacy-capitolo class="pt-0"
                                  sub-titolo="I tuoi diritti relativi alla privacy in Brasile"
                >
                    Hai il diritto di:<br>
                    <br>
                    <ul>
                        <li>
                            ottenere conferma della esistenza di attività di trattamento riguardanti le tue informazioni
                            personali;
                        </li>
                        <li>
                            accedere alle tue informazioni personali;
                        </li>
                        <li>
                            ottenere la rettifica delle tue informazioni personali incomplete, inaccurate o non aggiornate;
                        </li>
                        <li>
                            ottenere l’anonimizzazione, il blocco o la cancellazione delle informazioni personali non
                            necessarie o eccessive, o di quelle informazioni che sono trattate in contrasto alle disposizioni
                            della LGPD;
                        </li>
                        <li>
                            ottenere informazioni riguardo la possibilità di prestare o rifiutare il tuo consenso e le
                            relative conseguenze;
                        </li>
                        <li>
                            ottenere informazioni riguardo le terze parti con le quali condividiamo le tue informazioni
                            personali;
                        </li>
                        <li>
                            ottenere, a fronte di una tua richiesta esplicita, la portabilità delle tue informazioni
                            personali (ad eccezione delle informazioni anonimizzate) verso altri fornitori di prodotti o
                            servizi, a condizione che i nostri segreti commerciali e industriali siano salvaguardati;
                        </li>
                        <li>
                            ottenere la cancellazione delle informazioni personali trattate qualora il trattamento sia stato
                            effettuato sulla base del tuo consenso, a meno che sia applicabile una o più delle eccezioni
                            previste dell’articolo 16 LGPD;
                        </li>
                        <li>
                            revocare il tuo consenso in qualsiasi momento;
                        </li>
                        <li>
                            presentare un reclamo riguardante le tue informazioni personali all’ANPD (Autorità Nazionale di
                            Protezione dei Dati) o ad un organismo di tutela dei consumatori;
                        </li>
                        <li>
                            opporsi ad attività di trattamento nei casi in cui tale trattamento non sia effettuato in
                            conformità alle disposizioni di legge;
                        </li>
                        <li>
                            richiedere informazioni chiare ed adeguate riguardo ai criteti e alle procedure utilizzate
                            nell’ambito dei processi decisionali automatizzati; e
                        </li>
                        <li>
                            richiedere la revisione delle decisioni che ledono i tuoi interessi, effettuate esclusivamente
                            sulla base di processi decisionali automatizzati delle tue informazioni personali. Queste
                            includono le decisioni per delineare il tuo profilo personale, professionale, di consumatore o
                            creditore, o altri spetti della tua personalità.
                        </li>
                    </ul>
                    <br>
                    Non sarai mai discriminato, né subirai in alcun modo alcun trattamento che ti sia sfavorevole, a seguito
                    dell’esercizio dei tuoi diritti.
                </privacy-capitolo>

                <privacy-capitolo sub-titolo="Come inoltrare una richiesta"
                                  testo="Puoi inoltrare una richiesta esplicita per esercitare i tuoi diritti a titolo gratuito, in qualsiasi momento, utilizzando i recapiti presenti in questo documento o tramite il tuo legale rappresentante."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Come e in quanto tempo gestiremo la tua richiesta">
                    Faremo il possibile per rispondere alla tua richiesta nel più breve tempo possibile.<br>
                    In ogni caso, se per noi fosse impossibile farlo, ci assicureremo di comunicarti le ragioni fattuali o
                    legali che ci impediscono di soddisfare immediatamente o di dar seguito alla tua richiesta. Qualora le
                    tue informazioni personali non siano processate da noi, se saremo in grado di farlo, ti indicheremo la
                    persona fisica o giuridica alla quale rivolgere le tue richieste.<br>
                    <br>
                    Nel caso in cui tu decida di inoltrare una richiesta di <strong>accesso</strong> o una richiesta di
                    <strong>conferma dell’esistenza del trattamento</strong> di informazioni personali, ti preghiamo di
                    assicurarti di specificare se preferisci ricevere le tue informazioni personali in formato elettronico o
                    cartaceo.<br>Dovrai inoltre farci sapere se desideri una risposta immediata, nel qual caso riceverai una
                    risposta semplificata, o se invece necessiti di una informativa completa.<br>In quest’ultimo caso,
                    risponderemo entro 15 giorni dal momento della tua richiesta, fornendoti tutte le informazioni
                    riguardanti l’origine delle tue informazioni personali, la conferma o meno dell’esistenza di informazioni
                    personali che ti riguardano, tutti i criteri utilizzati per il trattamento e le finalità di tale
                    trattamento, salvaguardando al contempo i nostri segreti commerciali e industriali.<br>
                    <br>
                    Nel caso in cui tu decida di inoltrare una richiesta di
                    <strong>rettifica, cancellazione, anonimizzazione o blocco delle informazioni personali</strong>, ci
                    assicureremo di informare immediatamente della tua richiesta le altre parti con le quali abbiamo
                    condiviso le tue informazioni personali in modo che esse possano a loro volta soddisfare la tua richiesta
                    - ad esclusione dei casi in cui una tale comunicazione risulti per noi impossibile o eccessivamente
                    onerosa.
                </privacy-capitolo>

                <privacy-capitolo
                    sub-titolo="Trasferimento delle informazioni personali al di fuori del Brasile nei casi consentiti dalla legge"
                >
                    Possiamo trasferire le tue informazioni personali al di fuori del territorio brasiliano nel seguenti
                    casi:<br>
                    <br>
                    <ul>
                        <li>
                            quando il trasferimento è necessario per la cooperazione giuridica internazionale tra i servizi
                            di intelligence, gli organi investigativi e di procedura penale, così come previsto dagli
                            stumenti messi a disposizione dal diritto internazionale;
                        </li>
                        <li>
                            quando il trasferimento è necessario per difendere la vita o l’incolumità fisica tua o di terze
                            parti;
                        </li>
                        <li>
                            quando il trasferimento è autorizzato dall’ANPD;
                        </li>
                        <li>
                            quando il trasferimento deriva da un’obbligazione assunta nel contesto di un accordo di
                            cooperazione internazionale;
                        </li>
                        <li>
                            quando il trasferimento è necessario all’esercizio di un ordine pubblico o per l’espletamento di
                            un pubblico servizio;
                        </li>
                        <li>
                            quando il trasferimento è necessario per l’adempimento di un obbligo di legge, l’esecuzione di un
                            contratto e dei relativi adempimenti precontrattuali, o il normale esercizio di diritti in sede
                            guidiziaria, amministrativa o in sede arbitrale.
                        </li>
                    </ul>
                </privacy-capitolo>

                <privacy-capitolo titolo="Definizioni e riferimenti legali"
                                  sub-titolo="Dati Personali (o Dati)"
                                  testo="Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Dati di Utilizzo"
                                  testo="Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Utente"
                                  testo="L'individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l'Interessato."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Interessato"
                                  testo="La persona fisica cui si riferiscono i Dati Personali."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Responsabile del Trattamento (o Responsabile)"
                                  testo="La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Titolare del Trattamento (o Titolare)"
                                  testo="La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questa Applicazione."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Questa Applicazione"
                                  testo="Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Servizio"
                                  testo="Il Servizio fornito da questa Applicazione così come definito nei relativi termini (se presenti) su questo sito/applicazione."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Unione Europea (o UE)"
                                  testo="Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo."
                ></privacy-capitolo>

                <privacy-capitolo sub-titolo="Riferimenti legali">
                    La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli
                    artt. 13 e 14 del Regolamento (UE) 2016/679.<br>
                    <br>
                    Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.
                </privacy-capitolo>

                <h-col cols="12">
                    <hr>
                </h-col>

                <h-col cols="12">
                    <i>Ultima modifica: 1 marzo 2022</i>
                </h-col>

            </h-row>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import PrivacyCapitolo from '@/components/app/privacy/PrivacyCapitolo';

    export default {
        name: 'Privacy',
        components: {HRow, HCol, PrivacyCapitolo}
    }
</script>

<style scoped>

</style>
